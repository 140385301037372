import React from 'react'
import TermCondition from './TermCondition'

const TermMain = () => {
  return (
    <div>
<TermCondition />

    </div>
  )
}

export default TermMain