import React from 'react'
import './center.css'
import CenTer from './CenTer'
import CenterMain  from './CenterMain'

const ContactUs = () => {
  return (
    <div className='contact-uss'>

     
          <CenTer />
          <CenterMain />
       </div>
  )
}

export default ContactUs