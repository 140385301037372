import React from 'react';
import './download.css';
import img2 from '../image/img2.png';
import img3 from '../image/img3.png';

const DownLoad = () => {
  return (
    <div>
      <div className="Download">
        <div className="download-scnd">
          <h1>Download Now</h1>
          <div className="download-main">
            <img
              src={img2}
              alt=""
              onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=com.houseowls.houseowls_mob'}
            />
            <img
              src={img3}
              alt=""
              onClick={() => window.location.href = 'https://apps.apple.com/in/app/houseowls-buy-rent-sell/id6473515459'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownLoad;
