import React from 'react'
import './center.css'

function CenTer() {
  return (
    <div>
<div className="center1">
   <h2>Contact Us</h2>
</div>

    </div>
  )
}

export default CenTer