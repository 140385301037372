import React from 'react'
import PrivacyPlociy from '../privacy/PrivacyPolicy'
const PrivacyMain = () => {
  return (
    <div>
<PrivacyPlociy />

    </div>
  )
}

export default PrivacyMain