import React from 'react'
// import group from './image/group.svg'
// import objects from '../image/objects.svg'
import banner from './banner.css'

const BanNer = () => {
  return (
    <div className='bdr'>
      <hr />
      <div className="container">
        <div className="center">
          {/* <img src={objects} alt="" /> */}
        </div>
        <div className="center-main">
          <h2>World’s First Video-Based Proptech Platform</h2>
          <button>Made with ❤️ in India</button>
        </div>

      </div>
      <br />
    </div>
  )
}

export default BanNer;